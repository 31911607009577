<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 74%;display: flex;">
          <el-input
            class="hunt"
            v-model="XM"
            placeholder="请输入会员姓名"
            size="large"
            @input="orangutan"
          />
          <el-input
            class="hunt"
            v-model="BM"
            placeholder="请输入会员编码"
            size="large"
            @input="orangutan"
          />
          <!-- 组织名称 -->
          <el-select
            class="hunt"
            @focus="closeIt"
            v-model="ability"
            placeholder="请选择所属组织"
            size="large"
            @click="multiple"
            clearable
            @change="numerical"
            ref="selectIt"
          />
          <!-- 是否启用 -->
          <el-select class="hunt"  v-model="values" placeholder="是否设置" clearable @clear="empty" size="large">
            <el-option label="是" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
      <div class="summation">
        <div class="hint">统计 : {{totality}}条</div>
          <div class="setting">
            
            <!-- 批量导入 <img src="../../assets/daoru.png" style="width:22px;margin-right: 6px" alt=""> -->
            <!-- <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="default"
                @click="payment"
                >批量导入</el-button
              >
            </div> -->
            <!-- 批量导入 -->
            <el-upload
              v-model:file-list="fileList"
              class="upload-demo"
              style="margin-left: 10px"
              action
              :auto-upload="true" 
              :show-file-list="false"
              name="excelFile"
              accept=".xlsx,.xls"
              :limit='1'
              :on-error="error"
              :http-request="uploadHttpRequest"
              ref='pleased'
              :disabled='jinyong'
            >
              <el-button type="primary" :loading="acting" :disabled='jinyong'>批量导入</el-button>
            </el-upload>
            <!-- 下载模板 -->
            <div>
              <el-button
                style="margin-left: 10px"
                type="warning"
                size="default"
                @click="append"
                >下载模板</el-button
              >
            </div>
            <!-- 会费操作日志 -->
            <div>
              <el-button
                style="margin-left: 10px"
                type="success"
                size="default"
                @click="journal"
                >会费操作日志</el-button
              >
            </div>
            <!-- 导入失败日志 -->
            <div>
              <el-button
                style="margin-left: 10px"
                type="danger"
                size="default"
                @click="channel"
                >导入失败日志</el-button
              >
            </div>
            <!-- 搜索导出 -->
            <el-dropdown style="margin-left: 12px;" loading split-button type="primary" @command="handleCommand" :hide-on-click="around">
               <div class="loading" v-show="wrought"></div>

                导出
                <template #dropdown>
                  <el-dropdown-menu style="width: 90px;">
                    <el-dropdown-item command="personage">导出数据</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
        @row-click="unit"
      >
        <!-- 会员姓名 -->
        <el-table-column
          label="会员姓名"
          prop="name"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 会员编码 :formatter="tiems" -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属工会 -->
        <el-table-column align="center" label="所属工会" :show-overflow-tooltip='true' prop="unionName" />
        <!-- 所属部门 -->
        <el-table-column align="center" label="所属部门" :show-overflow-tooltip='true' prop="departmentName" />
        <!-- 是否设置 -->
        <el-table-column align="center" label="是否设置" :show-overflow-tooltip='true' prop="isSetPayData" />
        <!-- 设置日期 -->
        <el-table-column align="center" label="设置日期" :show-overflow-tooltip='true' prop="setPayDataTime" />
        <!-- 缴费金额 -->
        <el-table-column align="center" label="应缴金额" :show-overflow-tooltip='true' prop="cost" />
        <!-- 编辑 -->
        <el-table-column label="操作" align="center" width="240">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.userId,scope.row.cost)"
              class="bulur"
              ><el-icon class="cancel"> <Setting /> </el-icon>设置缴费</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]" 
        :page-size="pagesize"
        :total="totality"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large" @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
    <!-- 导入任务提示 -->
    <div>
      <s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
        <div class="task">
          {{quoth}}
        </div>
      </s3-layer>
    </div>
    <!-- 导出任务提示 -->
    <div>
      <s3-layer v-model="educe" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
        <div class="task">
          {{reminder}}
        </div>
      </s3-layer>
    </div>
  </div>
</template>
<script>
import { Search,Setting,EditPen} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import{ ref,onMounted,onActivated } from 'vue'
import { HFfigure,Zzlist,HFdownload,HFchannel,HFderive } from '../../utils/api'
import moments from 'moment'
import qs from 'qs'
import { ElMessage } from "element-plus";
export default {
  name:"HFset",
  setup() {
    // 会费操作日志跳转
    let journal=()=>{
      router.push('/HFmember')
    }




    const route = useRoute();
    // 导出成功弹窗
    let reminder=ref('')
    let educe=ref(false)
    // 导出接口
    const payout=()=>{
      // console.log('会员姓名',XM.value)
      // console.log('会员编码',BM.value)
      // console.log('组织code',Hcode.value)
      // console.log('是否设置',values.value)
      let data={
        name:XM.value,
        username:BM.value,
        unionCode:Hcode.value,
        isSetPayData:values.value
      }
      HFderive(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          educe.value=true
          reminder.value='任务已提交，请在右侧查看进度'
        }else{
          educe.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
      })
    }
    // 导出按钮选择
    const handleCommand=(command)=>{
      console.log(command)
      if(command=='personage'){
        payout()
      }
    }







    // 导入任务弹窗提示
    let quoth=ref('')
    let anew=ref(false)
    // 批量导入按钮禁用
     const acting=ref(false)
     const jinyong=ref(false)
    // 批量导入
    const pleased=ref()
    const uploadHttpRequest = (data)=>{
      jinyong.value=true
      acting.value=true
      const file = data.file;
      const formData = new FormData()
      formData.append('file', file)
      let datas={
        excelFile:formData.get('file')
      }
      HFchannel(qs.parse(datas)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
            anew.value=true
            quoth.value='任务已提交，请在右侧查看进度'
            pleased.value.clearFiles()
            jinyong.value=false
            acting.value=false
        }else if(res.data.code==-1){
          ElMessage({
            message: '导入失败',
            type: "error",
            center: true,
          });
          acting.value=false
          jinyong.value=false
        }else if(res.data.code!==0||res.data.code!==-1){
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
          acting.value=false
          jinyong.value=false
        }
      })
    }





















    // 导入日志跳转
    let channel=()=>{
      router.push('/HFguide')
    }
    // const form = reactive({
    //   name:"",
    //   breviary:"",
    // })
    // 设置缴费跳转
    let payment=()=>{
      router.push('/HFset')
    }
    // 设置提示按钮弹窗
    // let standard =ref(false)
    // 点击每一行跳转
    let unit=(row)=>{
      console.log(row)
      // sessionStorage.setItem('ZNXQ', row.id)
      // router.push('/ZNxiangq')
    }
    let selectIt=ref()
    // 下拉隐藏
    let closeIt=()=>{
      selectIt.value.blur();
    }
    // 删除弹窗
    let remove=ref(false)
    // 会员姓名输入框改变
    // const orangutan=()=>{
    //   if(input.value==''&&ability.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 选择组织名称变化
    let numerical=()=>{
      // if(input.value==''&&ability.value==''&&values.value==''){
      //   list()
      // }
      Hcode.value=''
    }
    // 会员i姓名
    let XM=ref('')
    // 会员编码
    let BM=ref('')
    // 搜索加载
    const formative =ref(false)
    const search1=()=>{
      formative.value=true
      search()
    }
    // 搜索按钮
    let search=()=>{
      console.log('会员姓名',XM.value)
      console.log('会员编码',BM.value)
      console.log('组织code',Hcode.value)
      console.log('是否设置',values.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        name:XM.value,
        username:BM.value,
        unionCode:Hcode.value,
        isSetPayData:values.value
      }
      HFfigure(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          formative.value=false
          // 列表总数
          totality.value=res.data.data.page.totalCount
          // 列表数据
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
              texts.value='暂无数据'
            }
        }else{
          educe.value=false
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
        }
        console.log(res)
      })
    }
    // 是否启用
    let values=ref('')
    // 标题内容
    let input=ref('')
    // 搜索框弹窗
    // 获取code
    let Hcode=ref('')
    // 获取选项
    let ability=ref('')
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    let visible=ref(false)
    let multiple=()=>{
      visible.value=true
    }
    // 搜索框
    let texture=ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取所有组织列表
    let own=()=>{
      Zzlist().then((res)=>{
        console.log(res)
        if(res.data.code==0){
          texture.value=res.data.data.wholeUnionList
        }
      })
    }
    // 加载中 暂无数据列表
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      if(translate.value==1){
        XM.value=''
        BM.value=''
        ability.value=''
        Hcode.value=''
        values.value=''
        texts.value='加载中'
        branches.value=6
        currentPage.value=1
        list()
        own()
        // hint()
      }
    })
    let texts=ref('加载中')
    onMounted(()=>{
      list()
      // 所有组织列表接口
      own()
    })
     // 时间转换
    let tiems=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format().substring(0,10)
    }
    }
    // 分页处理
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(XM.value!==''||BM.value!==''|| ability.value!==''||values.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(XM.value!==''||BM.value!==''|| ability.value!==''||values.value!==''){
        search()
      }else{
        list()
      }
    }
    // 数据增加
    let branches=ref(6)
    // 数据总数
    let totality =ref(null)
    // 获取人物列表
    let list =()=>{
      let data={
        limit:branches.value,
        page:currentPage.value,
      }
      HFfigure(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          // 列表总数
          totality.value=res.data.data.page.totalCount
          // 列表数据
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 编辑跳转
    const copyreader = (id,cost)=>{
      sessionStorage.setItem('HFamount', id)
      sessionStorage.setItem('HFamountS', cost)
      router.push({ name: 'HFamount', params: { UserId: 1 }});
      // console.log(id,cost)
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      HFdownload().then((res)=>{
        let iframe = document.createElement('iframe')
        iframe.src=res.data.data.excelExampleUrl
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
        setTimeout(() => {
          document.body.removeChild(iframe)
        }, 1000)
      })
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      search1,
      formative,
      journal,
      // 导出成功弹窗
      reminder,
      educe,
      // 导出按钮
      handleCommand,
      // 导入成功弹窗提示
      quoth,
      anew,
      // 批量导入
      acting,
      jinyong,
      uploadHttpRequest,
      pleased,
      XM,
      BM,
      // 导入日志跳转
      channel,
      payment,
      // 设置提示弹窗
      // form,
      // standard,
      // 是否启用
      values,
      unit,
      selectIt,
      closeIt,
      numerical,
      // orangutan,
      // 标题内容
      input,
      // 搜索按钮
      search,
      // 搜索框
      ability,
      dendrogram,
      visible,
      multiple,
      defaultProps,
      texture,
      // 分页处理
      handleCurrentChange,
      handleSizeChange,
      currentPage,
      pagesize,
      // 加载中 暂无数据
      texts,
      tiems,
      // 列表总数
      totality,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Search,
    Setting,
    EditPen
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form{
  display: flex;
      flex-direction: column;
    align-items: flex-end;
}
.setting{
  display: flex;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.summation{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
